import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import whatsappIcon from "../../assets/whatsapp.png";
import callIcon from "../../assets/call.png";
import { ProjectHourService } from "../../apis/service/ProjectHourServices";

export default function Employee() {
  const [hoursBox, setHoursBox] = useState();
  const [selectedDate, setSelectedDate] = useState(
    getFormattedDate(new Date())
  );
  const [dates, setDates] = useState([]);

  useEffect(() => {
    setDates(generateDatesFromStartOfYear());
  }, []);

  const getHours = (date) => {
    ProjectHourService.getProjectsHours(date)
      .then((res) => {
        setHoursBox(res);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getHours(selectedDate);
  }, []);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    getHours(e.target.value);
  };
  return (
    <div>
      <div className={styles.dateSelector}>
        <label htmlFor="dateSelect">Here You Can Select A Date:</label>
        <select
          id="dateSelect"
          value={selectedDate}
          onChange={(e) => {
            handleDateChange(e);
          }}
        >
          {dates.map((date) => (
            <option key={date} value={date}>
              {date}
            </option>
          ))}
        </select>
      </div>

      <h4 style={{ marginInlineStart: "20px" }}>
        People who submitted their hours :{" "}
        {hoursBox?.working_hours_people?.users_with_hours_count} out of 51
      </h4>
      <div className={styles.con}>
        {hoursBox?.working_hours_people?.users_with_hours.map((item) => (
          <div key={item.employee__email} className={styles.box}>
            <div className={styles.card}>
              <div>
                <h4>
                  {item.employee__username} worked for {item.total_hours} hours
                </h4>
              </div>
              <div className={styles.data}>
                <div>
                  {item.projects.map((project, index) => (
                    <div>
                      <p key={index}>
                        - {project.project_name} for {project.project_hour}{" "}
                        hours
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <h4 className={styles.title}>
        People who didn't submit their hours{" "}
        {hoursBox?.working_hours_people?.users_without_hours_count} out of 51
      </h4>
      <div className={styles.con}>
        {hoursBox?.working_hours_people?.users_without_hours?.map((item) => (
          <div
            style={{
              display: "flex",
              gap: "2vw",
              marginInlineStart: "20px",
              alignItems: "center",
            }}
          >
            <h4>{item[0]}</h4>
            <h4>{item[2]}</h4>
            <a target="_blank" href={`tel:${item[1]}`} rel="noreferrer">
              <img src={callIcon} alt="call logo" />
            </a>

            <a
              target="_blank"
              href={`https://wa.me/2${item[1]}?text=Please%20don't%20forget%20to%20log%20your%20hours.`}
              rel="noreferrer"
            >
              <img src={whatsappIcon} alt="whatsapp logo" />
            </a>
          </div>
        ))}
      </div>
      <h4 style={{ marginInlineStart: "20px" }}>
        People On Vacation Or Sick Leave :{" "}
        {hoursBox?.working_hours_people?.users_with_day_off_count}
      </h4>
      <div className={styles.con}>
        {hoursBox?.working_hours_people?.users_with_day_off.map((item) => (
          <div
            style={{
              display: "flex",
              gap: "2vw",
              marginInlineStart: "20px",
              alignItems: "center",
            }}
          >
            <h4>{item.employee__email}</h4>
            <h4>{item.employee__title}</h4>
            <a
              target="_blank"
              href={`tel:${item.employee__phone}`}
              rel="noreferrer"
            >
              <img src={callIcon} alt="call logo" />
            </a>

            <a
              target="_blank"
              href={`https://wa.me/2${item.employee__phone}?text=Please%20don't%20forget%20to%20log%20your%20hours.`}
              rel="noreferrer"
            >
              <img src={whatsappIcon} alt="whatsapp logo" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

function getFormattedDate(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function generateDatesFromStartOfYear() {
  const dates = [];
  const startDate = new Date(new Date().getFullYear(), 0, 1);
  const endDate = new Date();

  let currentDate = startDate;
  while (currentDate <= endDate) {
    dates.push(getFormattedDate(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}
