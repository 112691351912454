export const ProjectHourEndpoint = {
  addProjectHour: {
    url: `/project-hour/`, // Define the URL for adding project hours
    method: "POST", // Use POST for adding data
  },
  addBillableHour: {
    url: `/billable-hours/`, // Define the URL for adding project hours
    method: "POST", // Use POST for adding data
  },
  projectHourCalculations: {
    url: (date) => `/project-hour/get_daily_report_hours/?date=${date}`,
    method: "GET",
  },
  addVacation: {
    url: `/project-hour/add_vacation/`,
    method: "POST"
  },
  ProjectCompletion: {
    url: `/project-completion-log/`,
    method: "POST",
  },
  ProjecDelayed: {
    url: `/delay_logs/`,
    method: "POST",
  }
};

