import { Network } from "../Network";
import { ProjectHourEndpoint } from "../endpoints/ProjectHourEndpoint";

export class ProjectHourService {
  static addProjectHour(projectHourData) {
    return Network.fetch(
      ProjectHourEndpoint.addProjectHour.url,
      {
        method: ProjectHourEndpoint.addProjectHour.method,
        body: JSON.stringify(projectHourData), // Convert the data to JSON and send it in the request body
      },
      true
    );
  }
  static addBillableHour(projectBillableHourData) {
    return Network.fetch(
      ProjectHourEndpoint.addBillableHour.url,
      {
        method: ProjectHourEndpoint.addBillableHour.method,
        body: JSON.stringify(projectBillableHourData), // Convert the data to JSON and send it in the request body
      },
      true
    );
  }
  static getProjectsHours(date) {
    return Network.fetch(
      ProjectHourEndpoint.projectHourCalculations.url(date),
      {
        method: ProjectHourEndpoint.projectHourCalculations.method
      },
      true
    )
  }
  static addVacation(date) {
    return Network.fetch(
      ProjectHourEndpoint.addVacation.url,
      {
        method: ProjectHourEndpoint.addVacation.method,
        body: JSON.stringify(date)
      },
      true
    )
  }
  static addProjectCompletion(date) {
    return Network.fetch(
      ProjectHourEndpoint.ProjectCompletion.url,
      {
        method: ProjectHourEndpoint.ProjectCompletion.method,
        body: JSON.stringify(date)
      },
      true
    )
  }
  static addProjectDelay(data) {
    return Network.fetch(
      ProjectHourEndpoint.ProjecDelayed.url,
      {
        method: ProjectHourEndpoint.ProjecDelayed.method,
        headers: {
          "content-type": "form-data",
        },
        body: data
      },
      true
    )
  }

}
