import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { ProjServices } from "../../../apis/service/ProjServices";
import "./ProjectRetainerDetailsStyle.css";

const ProjectRetainerDetails = () => {
  const { id } = useParams();

  const [project, setProject] = useState(null);
  const [timePassedPercent, setTimePassedPercent] = useState(0);
  const [currentMonth, setCurrentMonth] = useState("");
  const [departmentStats, setDepartmentStats] = useState([]);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const data = await ProjServices.getRetainerProjectsDetails(id);
        if (data && data.length > 0) {
          const projectData = data[0];
          setProject(projectData); console.log(projectData);
          calculateProjectStats(projectData);
        }
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    };

    fetchProjectDetails();
  }, [id]);

  const calculateProjectStats = (projectData) => {
    const currentDate = new Date();
    const totalDaysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const dayOfMonth = currentDate.getDate();
    const timePassed = (dayOfMonth / totalDaysInMonth) * 100;

    setTimePassedPercent(timePassed.toFixed(2));

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August",
      "September", "October", "November", "December"];
    setCurrentMonth(monthNames[currentDate.getMonth()]);

    const updatedDepartmentStats = projectData.department_employee.map(department => {

      let totalRequiredHours = 0;

      // target hours based on department 
      switch (department.department) {
        case "Backend":
          totalRequiredHours = department.backend_target_hours;
          break;
        case "Business Developer":
          totalRequiredHours = department.business_develope_target_hours;
          break;
        case "CEO":
          totalRequiredHours = department.seo_target_hours;
          break;
        case "Frontend":
          totalRequiredHours = department.front_end_target_hours;
          break;
        case "Project Manager":
          totalRequiredHours = department.project_manager_target_hours;
          break;
        case "UI/UX":
          totalRequiredHours = department.ui_ux_target_hours;
          break;
        case "Executive Assistant":
          totalRequiredHours = department.product_owner_target_hours + department.legal_target_hours + department.testing_target_hours;
          break;
        default:
          console.warn(`Unknown department: ${department.department}`);
      }

      const actualHours = department.actual_hours || 0;
      const billableHours = department.billable_hours || 0;

      // Calculate percentages based on target hours for the department
      const actualHoursPercent = totalRequiredHours ? (actualHours / totalRequiredHours) * 100 : 0;
      const billableHoursPercent = totalRequiredHours ? (billableHours / totalRequiredHours) * 100 : 0;
      const totalHoursPercent = totalRequiredHours ? ((actualHours + billableHours) / totalRequiredHours) * 100 : 0;

      return {
        department: department.department,
        totalRequiredHours: totalRequiredHours,

        actualHours: actualHours,
        actualHoursPercent: actualHoursPercent,

        billableHours: billableHours,
        billableHoursPercent: billableHoursPercent,

        totalHours: (actualHours + billableHours),
        totalHoursPercent: totalHoursPercent,
      };
    });

    // Set the department statistics in state
    setDepartmentStats(updatedDepartmentStats);
  };

  return (
    <div className="container">

      {project ? (
        <>
          <div className="contentCard">
            {/* Main Table */}
            <table className="excelTable">
              <thead>
                <tr>
                  <th>Project ID</th>
                  <th>Project Name</th>
                  <th>Contract Reference Number</th>
                  <th>Total Contracted Hours Per Month</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{project.id}</td>
                  <td>{project.name}</td>
                  <td>{project.contract_reference_number}</td>
                  <td>{project.total_hours}</td>
                </tr>
              </tbody>
            </table>

            {/*Table for Submission Status */}
            <table className="excelTable submissionTable">
              <thead>
                <tr>
                  <th>Submission Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {project.missing_hours && project.missing_hours.some(mh => mh.is_missing_hours)
                      ? `Those Employees [ ${project.missing_hours
                        .filter(mh => mh.is_missing_hours)
                        .map(mh => mh.username.split('@')[0])
                        .join(', ')} ] did not submit their hours.`
                      : "All departments have submitted their hours for the month."}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

           {/*Time Passed Table */}
          <div className="contentCard"> <table>
            <thead>
              <tr>
                <th>Time Passed in {currentMonth}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={2}>
                  <div className="progress-bar">
                    <div className="progress" style={{ width: `${timePassedPercent}%` }}>{timePassedPercent}%</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          </div>

             {/*department Stats Table */}
          <div className="contentCard">
            {departmentStats.map((dept, index) => (
              <table key={index} className="excelTable">
                <thead>
                  <tr>
                    <th>Department</th>
                    <th>Target Hours</th>
                    <th>Actual Hours</th>
                    <th>Billable Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{dept.department}</td>
                    <td>{dept.totalRequiredHours}</td>
                    <td colSpan={1}>
                      <div className="progress-bar">
                        <div className="progress" style={{ width: `${dept.actualHoursPercent}%` }}>{dept.actualHours}</div>
                      </div>
                    </td>
                    <td colSpan={1}>
                      <div className="progress-bar">
                        <div className="progress" style={{ width: `${dept.billableHoursPercent}%` }}>{dept.billableHours}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
          </div>

        </>
      ) : (
        <p>The project details are empty</p>
      )}
    </div>
  );

};
export default ProjectRetainerDetails;