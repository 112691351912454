import React from "react";
import { useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Styles from "./SideBar.module.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Link } from "react-router-dom";
import Logo from "../assets/bit68Sidebar.png";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

function SideBar({ username, title, handlelogout, manager }) {
  const [clickedLink1, setClickedLink1] = useState(); // Track clicked link, default to 0
  const [showMenu, setShowMenu] = useState(false); // Track whether the menu should be displayed

  const handleLink1Click = (index) => {
    setClickedLink1(index);
    setShowMenu(!showMenu);
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div>
      <aside className={Styles.sidebar}>
        <div className={Styles.logo}>
          <img alt="logo" src={Logo}></img>
        </div>
        <div className={Styles.userInfo}>
          {/* Display user information */}
          <p className={Styles.userInfoLine}>{username}</p>
          <p className={Styles.userInfoLine}>{title}</p>
        </div>
        <ul className={Styles.bmenuItem}>
          <li className={Styles.bmenuItem}>
            <Link to={"/"} className={`${Styles.bmenuItemlink} ${clickedLink1 === 1 ? Styles.clicked : ""}`}
              onClick={() => handleLink1Click(1)}>
              <ScheduleIcon className={Styles.AccessTimeIcon} />
              Time slot
            </Link>
          </li>
          <li className={Styles.bmenuItem}>
            <Link to={"/Projects"} className={`${Styles.bmenuItemlink} ${clickedLink1 === 3 ? Styles.clicked : ""}`}
              onClick={() => handleLink1Click(3)}>
              <ScheduleIcon className={Styles.AccessTimeIcon} />
              My Projects
            </Link>
          </li>
          {manager === "true" ? <li className={Styles.bmenuItem}>
            <Link to={"/Managers"} className={`${Styles.bmenuItemlink} ${clickedLink1 === 2 ? Styles.clicked : ""}`}
              onClick={() => handleLink1Click(2)}>
              <ScheduleIcon className={Styles.AccessTimeIcon} />
              Managers
            </Link>
          </li> : null}
          {manager === "true" ? <li className={Styles.bmenuItem}>
            <Link to={"/holidays"} className={`${Styles.bmenuItemlink} ${clickedLink1 === 4 ? Styles.clicked : ""}`}
              onClick={() => handleLink1Click(4)}>
              <ScheduleIcon className={Styles.AccessTimeIcon} />
              holidays
            </Link>
          </li> : null}
          {manager === "true" ? <li className={Styles.bmenuItem}>
            <Link to={"/vacations"} className={`${Styles.bmenuItemlink} ${clickedLink1 === 5 ? Styles.clicked : ""}`}
              onClick={() => handleLink1Click(5)}>
              <ScheduleIcon className={Styles.AccessTimeIcon} />
              Vacations
            </Link>
          </li> : null}
          {manager === "true" ? <li className={Styles.bmenuItem}>
            <Link to={"/ProjectRetainer"} className={`${Styles.bmenuItemlink} ${clickedLink1 === 6 ? Styles.clicked : ""}`}
              onClick={() => handleLink1Click(6)}>
              <ScheduleIcon className={Styles.AccessTimeIcon} />
              Project Retainer 
            </Link>
          </li> : null}
          {manager === "true" ? <li className={Styles.bmenuItem}>
            <Link to={"/ProjectsDetails"} className={`${Styles.bmenuItemlink} ${clickedLink1 === 7 ? Styles.clicked : ""}`}
              onClick={() => handleLink1Click(7)}>
              <ScheduleIcon className={Styles.AccessTimeIcon} />
              Project Details 
            </Link>
          </li> : null}
        </ul>
        <div className={Styles.logoutButton} onClick={handlelogout} ><ExitToAppIcon /> Logout </div>
        <div className={Styles.icon} onClick={toggleMenu}>
          {showMenu ? (<CloseIcon />) : (<MenuIcon /> )}
        </div>
      </aside>
      {showMenu && (
        <div className={Styles.smenuContainer}>
          <ul className={Styles.smenuItem}>
            <li className={Styles.smenuItem}>
              <Link to={"/"} className={`${Styles.smenuItemlink}`} onClick={() => handleLink1Click(0)}>
                <AccessTimeIcon className={Styles.AccessTimeIcon} />Time slot
              </Link>
            </li>
            <li className={Styles.smenuItem}>
              <Link to={"/Projects"} className={`${Styles.smenuItemlink}`} onClick={() => handleLink1Click(3)}>
                <AccessTimeIcon className={Styles.AccessTimeIcon} />My Projects
              </Link>
            </li>
            
            {manager === "true" ? <li className={Styles.smenuItem}>
              <Link to={"/Managers"} className={`${Styles.smenuItemlink}`} onClick={() => handleLink1Click(2)}>
                <ScheduleIcon className={Styles.AccessTimeIcon} /> Managers
              </Link>
            </li> : null}
            {manager === "true" ? <li className={Styles.smenuItem}>
              <Link to={"/holidays"} className={`${Styles.smenuItemlink} `} onClick={() => handleLink1Click(4)}>
                <ScheduleIcon className={Styles.AccessTimeIcon} />holidays
              </Link>
            </li> : null}
            {manager === "true" ? <li className={Styles.smenuItem}>
              <Link to={"/vacations"} className={`${Styles.smenuItemlink} `} onClick={() => handleLink1Click(5)}>
                <ScheduleIcon className={Styles.AccessTimeIcon} /> Vacations
              </Link>
            </li> : null}
            {manager === "true" ? <li className={Styles.smenuItem}>
              <Link to={"/ProjectRetainer"} className={`${Styles.smenuItemlink} `} onClick={() => handleLink1Click(6)}>
                <ScheduleIcon className={Styles.AccessTimeIcon} /> Project Retainer
              </Link>
            </li> : null}
            {manager === "true" ? <li className={Styles.smenuItem}>
              <Link to={"/ProjectsDetails"} className={`${Styles.smenuItemlink} `} onClick={() => handleLink1Click(7)}>
                <ScheduleIcon className={Styles.AccessTimeIcon} /> Projects Details
              </Link>
            </li> : null}
            <li className={Styles.smenuItem} onClick={handlelogout}>
              <p><ExitToAppIcon className={Styles.AccessTimeIcon} />Logout </p>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
export default SideBar;
