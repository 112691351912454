import React from "react";

import Employee from "../../../Components/employee";
import styles from "./style.module.css";

export default function Managers() {
  return (
    <div className={styles.container}>
      <div style={{ paddingTop: "30px" }}></div>
      <Employee />
    </div>
  );
}
