export const ProjectEndpoint = {
  project: {
    url: `/project/`,
    method: "GET",
  },
  allProjects: {
    url: `/project/user_projects/`,
    method: "GET",
  },
  retainerProjects:{ 
    url: `/project/?project_type=retainer`,
    method: "GET",
  },
  retainerProjectsDetails:{ 
    url: (id) => `/project/${id}/project_retainer/`,
    method: "GET",
  },
  projectsDetails:{ 
    url: `/project/?project_type=scope`,
    method: "GET",
  },
  singleProjectsDetails:{ 
    url: (id) => `/project/${id}/detail_project/`,
    method: "GET",
  },
  getDays: {
    url: `/time-slots/`,
    method: "GET",
  },
  getTodayProjects: {
    url: `/project-hour/user_recent_hours/`,
    method: "GET",
  },
  getProjectsCompilition: {
    url: `/project-completion-log/`,
    method: "GET",
  }
};

